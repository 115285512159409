import React from 'react';

function Copyright() {

    return (
      <div className="copyright">
        &copy; 2022 Rooters s.r.l.s. Some rights reserved. - <a href="https://github.com/r00ters/corporate-website">Source Code</a>
      </div>
    );
}

export default Copyright